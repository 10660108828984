import axios, { AxiosError } from "axios";

const onRejectedPlain = (error: AxiosError) => {
    // changed to "throw error", based on: https://brandoncc.dev/blog/how-to-handle-unhandledrejection-errors-using-axios/
    // used to be:
    // return Promise.reject(error);
    throw error;
};

const onRejectedLogoutOn404 = (error: AxiosError) => {
    if (error.response?.status === 401) {
        window.location.href = "/auth/logout";
    }
    onRejectedPlain(error);
};

const axiosInstanceFactory = ({
    accessToken,
    baseUrl,
    logoutOn401 = true,
}: {
    accessToken?: string;
    baseUrl?: string;
    logoutOn401?: boolean;
}) => {
    const axiosInstance = axios.create({
        ...(accessToken && {
            headers: { Authorization: accessToken },
        }),
        ...(baseUrl && { baseUrl }),
    });

    axiosInstance.interceptors.response.use(
        (response) => response,
        logoutOn401 ? onRejectedLogoutOn404 : onRejectedPlain
    );

    return axiosInstance;
};

export { axiosInstanceFactory };
