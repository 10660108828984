import axios, { AxiosError } from "axios";
import axiosRetry, { isNetworkError, isRetryableError } from "axios-retry";
import { showLoader } from "paul/native-dom-manipulation";

const initializeAxiosRetry = () =>
    axiosRetry(axios, {
        retries: 3,
        retryDelay(count) {
            return count * 1000;
        },
        retryCondition(error) {
            return isNetworkError(error) || isRetryableError(error);
        },
        onRetry() {
            showLoader();
        },
    });

const getMessageFromAxiosError = (error: AxiosError) => {
    return (
        (error.response?.data as { message?: string })?.message ??
        (error.response?.data as string) ??
        "Error"
    );
};

export default initializeAxiosRetry;
export { getMessageFromAxiosError };
